<template>
    <div class="RoleCreate">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="exchangeNote" label-width="80px" :rules="rules" size="small">
                <el-form-item label="分销员">
                    <div class="smsVerification">
                        <div v-if="tableData.length === 0" class="diaLogBox">
                            <el-form ref="changeMobile" :model="mobileFormData" :rules="smsVerificationRules">
                                <el-form-item label="" prop="mobile" style="margin-bottom: 20px">
                                    <el-input
                                        v-model="mobileFormData.mobile"
                                        style="width: 250px"
                                        placeholder="请输入分销员手机号码"
                                    />
                                </el-form-item>
                                <el-form-item label="" prop="sendCode" style="margin-bottom: 20px">
                                    <el-input
                                        placeholder="请输入验证码"
                                        v-model="mobileFormData.sendCode"
                                        class="input-with-select"
                                        @blur="checkVerifyCode"
                                        :disabled="!sendSmsSuccessFlag"
                                        style="width: 250px"
                                    >
                                        <div
                                            v-show="show"
                                            @click="getNewMobileVerificationCode"
                                            slot="append"
                                            style="color: #458bd3; cursor: default"
                                        >
                                            获取验证码
                                        </div>
                                        <div v-show="!show" slot="append">{{ count }}秒</div>
                                    </el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div v-if="tableData.length > 0">
                            <el-table
                                id="printMe"
                                border
                                stripe
                                style="width: 800px"
                                :data="tableData"
                                size="mini"
                                :highlight-current-row="true"
                                max-height="500"
                            >
                                <el-table-column prop="nickName" label="姓名" />
                                <el-table-column prop="mobile" label="手机号" />
                                <el-table-column prop="distributionStaffDept.departmentName" label="所属机构" />
                                <el-table-column prop="availableBusinessPoints" label="可用商分" />
                                <el-table-column prop="convertibleAmount" label="可兑换金额" />
                                <el-table-column
                                    label="操作"
                                    width="280"
                                    header-align="center"
                                    prop="operate"
                                    key="operate"
                                >
                                    <template>
                                        <el-button size="small" type="danger" @click="handleDelete(1)">删除 </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="兑换机构" prop="deptCode">
                    <el-select filterable @change="selectDept" v-model="exchangeNote.deptCode">
                        <el-option
                            v-for="group in meta.groups"
                            :value="group.code"
                            :key="group.code"
                            :label="group.departmentName"
                        />
                    </el-select>
                    <div class="limitTit" v-if="deptTitFlag">
                        <span>该分销员不属于本机构组，请确认无误后再进行核销。</span>
                    </div>
                </el-form-item>
                <el-form-item label="兑换类型" prop="exchangeType">
                    <el-radio-group v-model.number="exchangeNote.exchangeType" @change="typeChange">
                        <el-radio :label="1">商品</el-radio>
                        <el-radio :label="2">现金</el-radio>
                    </el-radio-group>
                    <div class="limitTit" v-if="exchangeNote.exchangeType == 1">
                        <span>本操作不扣除门店商品库存，兑换商品后请以其他方式出库，以保证库存准确性。</span>
                    </div>
                    <div v-if="exchangeNote.exchangeType === 1">
                        <el-button v-if="tableData2.length == 0" type="primary" @click="showPickUpGoods" size="small"
                            >选择商品</el-button
                        >
                        <el-table
                            id="printMe"
                            border
                            stripe
                            ref="table"
                            style="width: 800px"
                            :data="tableData2"
                            size="mini"
                            v-if="tableData2.length > 0"
                            :highlight-current-row="true"
                            max-height="500"
                        >
                            <el-table-column prop="sku.code" label="商品编码" />
                            <el-table-column prop="sku.name" label="商品名称" />
                            <el-table-column prop="sku.bars" label="条码" />
                            <el-table-column prop="sku.specs" label="规格" />
                            <el-table-column prop="sku.unit" label="单位" />
                            <el-table-column prop="retailPrice" label="销售价">
                                <template slot-scope="scope">
                                    {{ fmtTwo(scope.row.retailPrice) }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="goodsCount" label="数量">
                                <template>
                                    <el-form-item>
                                        <ef-price-input
                                            v-model="exchangeNote.goodsCount"
                                            :precision="0"
                                            :disabled="titFlg != 1"
                                            :max="limitCount"
                                            :min="1"
                                        />
                                        <div class="limitTit">
                                            <span v-if="titFlg === 1">兑换数量必须&lt;={{ limitCount }}</span>
                                            <span v-if="titFlg === 2">可用商分不足以兑换该商品</span>
                                            <span v-if="titFlg === 3">该商品无销售价，请调价后再进行兑换</span>
                                        </div>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" header-align="center" prop="operate" key="operate">
                                <template>
                                    <el-button size="small" type="danger" @click="handleDelete(2)">删除 </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div v-if="exchangeNote.exchangeType === 2">
                        <el-form-item label="兑换金额:" prop="exchangeMoney" style="margin-bottom: 20px">
                            <ef-price-input
                                style="width: 100px"
                                v-model="exchangeNote.exchangeMoney"
                                :max="limitMoney"
                                :precision="2"
                            />元
                        </el-form-item>
                        <div v-if="tableData.length > 0" class="limitTit">
                            <span>兑换金额必须&lt;={{ limitMoney }}元</span>
                        </div>
                    </div>
                </el-form-item>
                <ef-remark v-model="exchangeNote.remake" />
                <el-form-item>
                    <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <pick-up-goods ref="pickupGoods" @queryGoods="setSelectedGoods" />
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';
import EfRemark from 'components/EfRemark';
import Util from 'js/Util';
import PickUpGoods from 'components/PickUpGoods';
import EfPriceInput from 'components/EfPriceInput';

export default {
    name: 'RoleCreate',
    components: { EfRemark, PickUpGoods, EfPriceInput },
    data() {
        return {
            exchangeNote: {
                distributionUserId: '',
                userId: '',
                distributionNickName: '',
                distributionMobile: '',
                distributionStaffCode: '',
                deptCode: '',
                distributionStaffDeptCode: '',
                goodsCode: '',
                goodsName: '',
                goodsCount: undefined,
                skuCode: '',
                exchangeType: 1,
                remake: '',
                goodsPrice: undefined,
                exchangeMoney: undefined,
                exchangeScore: '',
            },
            deptGroupCode: '',
            deptTitFlag: false,
            limitCount: undefined,
            limitMoney: undefined,
            titFlg: 1,
            loadingDeptGroupFlag: true,
            sendSmsSuccessFlag: false,
            mobileFormData: {
                // 变更新手机号参数
                mobile: '',
                sendCode: '',
            },
            smsVerificationRules: {
                mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
                sendCode: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
            },
            tableData: [],
            tableData2: [],
            tabFlag: false,
            tabFlag2: false,
            countDownNum: null,
            countDownTimer: null,
            count: 60,
            show: true,
            meta: {
                groups: [],
                deptCodes: [],
            },
            rules: {
                deptCode: [{ required: true, message: '兑换机构必填', trigger: 'change' }],
                exchangeType: [{ required: true, message: '兑换类型必填', trigger: 'change' }],
                remake: [{ required: true, max: 200, message: '请输入备注,长度不超过200', trigger: 'blur' }],
            },
        };
    },
    watch: {
        'exchangeNote.goodsCount': function (newVal, oldVal) {
            if (newVal === 0 || newVal > this.limitCount) {
                this.$message.error('请输入正确的兑换数量');
            }
        },
        'exchangeNote.exchangeMoney': function (newVal, oldVal) {
            if (newVal === 0 || newVal > this.limitMoney) {
                this.$message.error('请输入正确的兑换金额');
                this.$nextTick(() => {
                    this.exchangeNote.exchangeMoney = undefined;
                });
            }
        },
        'mobileFormData.mobile': function (newVal, oldVal) {
            this.sendSmsSuccessFlag = false;
        },
    },
    mounted() {},
    methods: {
        typeChange(val) {
            this.exchangeNote.goodsCount = undefined;
            this.exchangeNote.exchangeMoney = undefined;
        },
        checkVerifyCode() {
            const _this = this;
            if (!this.sendSmsSuccessFlag) {
                _this.$message.error('您还没有成功发送验证码，请您重新发送');
                return;
            }
            if (this.mobileFormData.sendCode && this.sendSmsSuccessFlag) {
                _this.$http
                    .post('/distribution/extension/checkVerificationCode', _this.mobileFormData)
                    .then((rst) => {
                        if (rst.data.status == 200) {
                            _this.tabFlag = true;
                            _this.meta.groups = rst.data.data.departmentDtoList;
                            _this.tableData = [rst.data.data];
                            _this.limitMoney = rst.data.data.availableBusinessPoints / 100;
                            _this.exchangeNote.distributionUserId = rst.data.data.distributionUserId;
                            _this.exchangeNote.userId = rst.data.data.userid;
                            _this.exchangeNote.distributionNickName = rst.data.data.nickName;
                            _this.exchangeNote.distributionStaffCode = rst.data.data.staffCode;
                            _this.exchangeNote.distributionMobile = rst.data.data.mobile;
                            _this.exchangeNote.distributionStaffDeptCode =
                                rst.data.data.distributionStaffDept.code || '';
                            _this.exchangeNote.distributionStaffDeptName =
                                rst.data.data.distributionStaffDept.departmentName || '';
                            _this.deptGroupCode = rst.data.data.distributionStaffDept.deptGroupCode || '';
                        } else {
                            _this.$message.error(rst.data.message);
                        }
                    })
                    .catch((rst) => {
                        _this.$message.error(rst.response.data.message);
                    });
            }
        },
        getNewMobileVerificationCode: Util.debounce(function () {
            this.sendSmsSuccessFlag = false;
            const { mobile } = this.mobileFormData;
            if (mobile) {
                if (window.ef.someRegs.mobile.test(mobile)) {
                    this.$http.get('/distribution/extension/sendVerificationCode' + '?mobile=' + mobile).then((rst) => {
                        if (rst.status == 200) {
                            this.countDown();
                            this.sendSmsSuccessFlag = true;
                        }
                    });
                } else {
                    this.$message.error('请输入正确的手机号');
                }
            } else {
                this.$message.error('请输入手机号码');
            }
        }),
        handleDelete(num) {
            if (num === 1) {
                Object.assign(this.$data, this.$options.data());
            }
            if (num === 2) {
                this.tableData2 = [];
            }
        },
        showPickUpGoods() {
            this.$refs.pickupGoods.show({ deptCode: this.exchangeNote.deptCode }, '123', true);
        },
        selectDept(value) {
            const a = this.meta.groups.find((e) => {
                return e.code === value;
            });
            if (a.deptGroupCode == this.deptGroupCode) {
                this.deptTitFlag = false;
            } else {
                this.deptTitFlag = true;
            }
        },
        setSelectedGoods(selectedGoods) {
            this.tableData2 = selectedGoods;
            this.limitCount = Math.floor(
                this.tableData[0].availableBusinessPoints / this.fmtTwo(selectedGoods[0].retailPrice) / 100
            );
            if (this.limitCount < 1) {
                this.titFlg = 2;
            } else {
                this.titFlg = 1;
            }
            if (!selectedGoods[0].retailPrice) {
                this.titFlg = 3;
            }
            this.exchangeNote.goodsCode = selectedGoods[0].code;
            this.exchangeNote.goodsName = selectedGoods[0].sku.name;
            this.exchangeNote.skuCode = selectedGoods[0].skuCode;
            this.exchangeNote.goodsPrice = this.fmtTwo(selectedGoods[0].retailPrice);
        },
        countDown() {
            const TIME_COUNT = 60;
            if (!this.countDownTimer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.countDownTimer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                    } else {
                        this.show = true;
                        clearInterval(this.countDownTimer);
                        this.countDownTimer = null;
                    }
                }, 1000);
            }
        },
        handleSave() {
            this.exchangeNote.exchangeScore = this.exchangeNote.goodsCount * this.exchangeNote.goodsPrice * 100;
            const _this = this;
            this.$refs.form.validate((valid) => {
                if (!valid) return;
                if (_this.tableData.length == 0) {
                    _this.$message.error('请选择分销员');
                    return;
                }
                if (_this.exchangeNote.exchangeType === 1 && _this.tableData2.length == 0) {
                    _this.$message.error('请选择兑换商品');
                    return;
                }
                if (_this.exchangeNote.exchangeType === 1 && _this.exchangeNote.goodsCount == undefined) {
                    _this.$message.error('请输入正确的兑换商品数量');
                    return;
                }
                if (_this.exchangeNote.exchangeType === 2 && _this.exchangeNote.exchangeMoney == undefined) {
                    _this.$message.error('请输入正确的兑换金额');
                    return;
                }
                UrlUtils.PostPriceBatchRemote(
                    this,
                    '/distribution/extension/businessPointsForSave',
                    this.exchangeNote,
                    null,
                    (data) => {
                        _this.$message.success('保存成功');
                        this.goBackAndReload();
                    }
                );
            });
        },
    },
};
</script>
<style scoped>
.el-input {
    width: auto;
}

.el-textarea {
    width: auto;
}

.limitTit {
    color: red;
    font-size: 12px;
    line-height: 1.5;
}
</style>
